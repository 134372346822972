<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";

/**
 * User Business Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('navbar.dropdown.business'),
          active: true
        }
      ]
    },

    save() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.form);

      axios.put(`/user/company`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ml-2"
          },
          buttonsStyling: false
        });

        swalWithBootstrapButtons.fire(
            this.$t('message.saved'),
            this.$t('message.success'),
            "success"
        );
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    onSelectCountry({dialCode}) {
      this.dialCode = dialCode
    }
  },

  data() {
    return {
      form: {
        taxNumber: "",
        companyName: "",
        street: "",
        buildingNumber: "",
        postcode: "",
        city: "",
        country: ""
      },

      submitted: false,
    }
  },

  validations: {
    form: {
      taxNumber: { required },
      companyName: { required },
      street: { required },
      buildingNumber: { required },
      postcode: { required },
      city: { required },
      country: { required }
    }
  },

  async created() {
    try {
      const result = await axios.get(`/user/company`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      })

      if (result.data) {
        this.form = result.data
      }

    } catch (error) {
      console.log(error)
    }
  }

}
</script>

<template>
  <Layout>
    <PageHeader :title="$t('navbar.dropdown.business')" :items="getItems()" />
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <form class="needs-validation">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="tax">{{  $t('business.tax') }}</label>
                    <input
                        id="tax"
                        v-model="form.taxNumber"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.taxNumber.$error }"/>
                    <div v-if="submitted && $v.form.taxNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.taxNumber.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="companyName">{{ $t('business.company-name') }}</label>
                    <input
                        id="companyName"
                        v-model="form.companyName"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.companyName.$error }"/>
                    <div v-if="submitted && $v.form.companyName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.companyName.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="street">{{ $t('business.street') }}</label>
                    <input
                        id="street"
                        v-model="form.street"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.street.$error }"/>
                    <div v-if="submitted && $v.form.street.$error" class="invalid-feedback">
                      <span v-if="!$v.form.street.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="building-number">{{ $t('business.building-number') }}</label>
                    <input
                        id="building-number"
                        v-model="form.buildingNumber"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.buildingNumber.$error }"/>
                    <div v-if="submitted && $v.form.buildingNumber.$error" class="invalid-feedback">
                      <span v-if="!$v.form.buildingNumber.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="zip-code">{{ $t('business.postcode') }}</label>
                    <input
                        id="zip-code"
                        v-model="form.postcode"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.postcode.$error }"/>
                    <div v-if="submitted && $v.form.postcode.$error" class="invalid-feedback">
                      <span v-if="!$v.form.postcode.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="city">{{ $t('business.city') }}</label>
                    <input
                        id="city"
                        v-model="form.city"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.city.$error }"/>
                    <div v-if="submitted && $v.form.city.$error" class="invalid-feedback">
                      <span v-if="!$v.form.city.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>


                <div class="col-md-12">
                  <div class="form-group">
                    <label for="city">{{ $t('business.country') }}</label>
                    <input
                        id="city"
                        v-model="form.country"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.country.$error }"/>
                    <div v-if="submitted && $v.form.country.$error" class="invalid-feedback">
                      <span v-if="!$v.form.country.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

              </div>
              <b-button variant="primary" @click="save" class="w-sm ml-1">{{  $t('message.save') }}</b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>